<template lang='pug'>
games(:events="events")
</template>

<script>
import games from '@/components/games'
import { computed,onMounted } from 'vue'
import { useStore } from 'vuex'
export default {
	name:'nextGame',
	components: { games },
	setup(){
		const store = useStore()
		onMounted(store.dispatch('getResTable'))
		return {events: computed(() => store.getters.nextEvents)}
	}
}
</script>