<template lang='pug'>
h1 Расписание
nextGames
</template>

<script>
import nextGames from '@/components/nextGames'
export default {
	name: 'Results',
	components: {nextGames}
}
</script>
